<template>
  <div class="row discount mt-2">
    <b-col lg="12">
      <div class="card">
				<b-row align-h="center">
					<b-col lg="6">
						<div class="p-2">
							<div class="mt-2 text-center">Search Discount Code</div>
							<b-input-group class="mt-3">
								<b-form-input
									v-model="query_search"
									type="search"
									placeholder="Type discount code"
									@change="setQuerySearch"
									trim
								></b-form-input>
								<b-input-group-append>
									<b-button type="submit" variant="primary" @click="asyncFind">Search</b-button>
									<b-button type="button" variant="dark" class="ml-2" @click="asyncClearFind">Clear</b-button>
									<b-spinner v-show="isLoadingSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-col>
				</b-row><div class="card-body">
          <b-alert show>
            Laporan/List penggunaan discount oleh user.
          </b-alert>

					<div class="row justify-content-end pb-1">
						<span>
							<button class="btn btn-sm btn-dark" @click="actionGetDiscounts">
								<em class="fa fa-refresh"></em>
							</button>
						</span>
						<label for="" class="col-sm-auto">Count </label>
						<strong class="col-sm-auto">{{ totalRows }}</strong>
					</div>
					<b-table
						sticky-header="500px"
						:items="items"
						:fields="fields"
						:busy="isLoading"
						:isLoading="isLoading"
						show-empty
						responsive="xl"
						class="mt-2"
						hover
					>
						<template v-slot:table-busy>
							<div class="text-center text-dark my-2">
								<b-spinner
									class="align-middle"
									variant="dark"
									type="grow"
									label="Loading .."
								>
								</b-spinner>
								Loading ..
							</div>
						</template>
						<template #cell(No)="row">
							{{ (currentPage - 1) * perPage + (row.index + 1) }}
						</template>
						<template #cell(id)="row">
              <router-link
              :to="{ path: '/order/' + row.item.order_id + '/detail' }"
                class="link"
              >
                {{ row.item.order_id }}
              </router-link>
            </template>
            <template #cell(discount_name)="row">
              {{ row.item.discount_name }}
            </template>
            <template #cell(discount_value)="row">
              {{ formatPrice(row.item.discount_value,row.item.currency_code || 'IDR') }}
            </template>
            <template #cell(discount_type)="row">
              <div class="text-left">
                <discountype :types="row.item.discount_type" />
              </div>
            </template>
					</b-table>
				</div>
				<b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1 mt-2 pt-4">
					<b-col lg="3">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							aria-controls="my-table"
						/>
					</b-col>
				</b-row>
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import discountype from "../../components/discount/discounttype.vue";
export default {
  name: "discount",
  components: {
    discountype,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
			query_search: '',
			isLoadingSearch: false,
			fields: [
        { key:'No', label: 'No'},
        { key:'id', label: 'Order ID'},
        { key:'discount_name', label: 'Name'},
        { key:'discount_code', label: 'Discount Code'},
				{
          key:'discount_type',
          label: 'Type',
          thStyle: {
            paddingLeft: '20px',
            width: "100px"
          },
          tdStyle: { width: "100px" }
        },
        { key:'order.users.email', label: 'Email'},
        {
					key: "order.created",
					label: "Created",
					formatter:function(value) {
            return new Date(value).toString()
          },
				},
        { key:'discount_value', label: 'Discount'},
			],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.discounts.isLoadingUsage,
      isError: (state) => state.discounts.isErrorUsage,
      totalRows: (state) => state.discounts.totalRowsUsage,
      items: (state) => state.discounts.usage,
    }),
  },
  mounted() {
    this.actionGetDiscounts();
  },
  watch: {
    currentPage: function() {
      this.actionGetDiscounts();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Voucher Usage | Content Management System Ebooks Gramedia.com';
      }
    },
  },
  methods: {
    ...mapActions("discounts", [
      "fetchDiscountsUsage",
      "fetchDiscountsUsageByid",
    ]),
    actionGetDiscounts() {
      if (this.query_search == "") {
        let payload = {
          page: this.currentPage,
          limit: this.perPage,
        };

        this.fetchDiscountsUsage(payload);
      } else {
        this.asyncFind();
      }
    },
		setQuerySearch(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search = query;
			}, 1000);
		},
		asyncFind() {
			this.isLoadingSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search;
				if (!query) {
					this.actionGetDiscounts();
					this.isLoadingSearch = false;
					return;
				}
				this.fetchDiscountsUsageByid({ q: query })
					.then((response) => {
						this.items = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
			}, 1000);
		},
		asyncClearFind() {
			this.query_search = "";
			this.actionGetDiscounts();
			this.isLoadingSearch = false;
		},
    formatDate(tgl) {
      return moment(tgl).format("DD MMM YY");
    },
    formatDateValid(from, to) {
      return (
        moment(from).format("DD MMM YY") +
        " - " +
        moment(to).format("DD MMM YY")
      );
    },
    promotionType(key) {
      const objDiscountName = {
        1: "Discount Offers",
        2: "Discount Orders",
        3: "Discount payment gateway offer",
        4: "Discount paytment gateway order",
        5: "Discount Code",
      }
      return objDiscountName[key] ?? "Discount Offers";
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatPrice(value, currency) {
      if (!value) {
        return "0";
      }
      const objLocales = {
        'IDR': "id-ID",
        'USD': "en-US",
      };
      const currentLocales = objLocales[currency] ?? "id-ID";
      const formatter = new Intl.NumberFormat(currentLocales, {
        style: 'currency',
        currency: currency,
      });
      return formatter.format(value);
    },
  },
};
</script>