var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_vm.types == 5 ? _c('span', [_c('i', {
    staticClass: "fa fa-ticket",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Code ")]) : _vm._e(), _vm.types == 2 ? _c('span', [_c('i', {
    staticClass: "fa fa-cart",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Order ")]) : _vm._e(), _vm.types == 1 ? _c('span', [_c('i', {
    staticClass: "fa fa-tags",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Offers ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }