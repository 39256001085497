<template>
  <div class="text-center">
    <span v-if="types == 5">
      <i class="fa fa-ticket" aria-hidden="true"></i> Code
    </span>
    <span v-if="types == 2">
      <i class="fa fa-cart" aria-hidden="true"></i> Order
    </span>
    <span v-if="types == 1">
      <i class="fa fa-tags" aria-hidden="true"></i> Offers
    </span>
  </div>
</template>

<script>
export default {
  name: "discounttypes",
  props: {
    types: {
      types: Number,
      required: true
    }
  }
};
</script>
